var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"aware",rawName:"v-aware"}],staticClass:"flexible-testimonials",on:{"viewport":_vm.onResize}},[_c('div',{staticClass:"flexible-testimonials-wrapper wrapper"},[_c('h2',{staticClass:"flexible-testimonials__title h1",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('Carousel',{ref:"carousel",staticClass:"flexible-testimonials__carousel",attrs:{"items":_vm.content.items,"bullet-points":false,"prev-next":true,"full-bleed":true,"drag":true,"alignement":"left"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.content;
return [_c('article',{staticClass:"flexible-testimonials__item",class:{ shortened: item.text.length > _vm.textLimit }},[_c('h3',{staticClass:"flexible-testimonials__item__title"},[_c('strong',[_vm._v(_vm._s(item.name))]),_c('address',[_vm._v(_vm._s(item.city))])]),_c('div',{staticClass:"flexible-testimonials__item__text"},[_c('p',{staticClass:"flexible-testimonials__item__text__inner"},[_vm._v(" "+_vm._s(item.text)+" ")])]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"flexible-testimonials__item__icon icon",attrs:{"symbol":"ui-testimonial","size":"0 0 28 24"}}),(item.text.length > _vm.textLimit)?_c('GAction',{staticClass:"flexible-testimonials__item__button",attrs:{"content":{
              label: _vm.$t('read-more'),
              tag: 'button',
              icon: 'arrow',
              modifiers: ['light'],
            }},nativeOn:{"click":function($event){return _vm.onItemClick(item)}}}):_vm._e()],1)]}}])}),_c('div',{staticClass:"flexible-testimonials__progress",style:(("--progress: " + _vm.progress + "%"))}),(_vm.modalArticle)?_c('UiModal',{ref:"modal",staticClass:"flexible-testimonials__modal",on:{"after-leave":_vm.onModalAfterLeave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('article',{staticClass:"flexible-testimonials__item"},[_c('h3',{staticClass:"flexible-testimonials__item__title"},[_c('strong',[_vm._v(_vm._s(_vm.modalArticle.name))]),_c('address',[_vm._v(_vm._s(_vm.modalArticle.city))])]),_c('p',{staticClass:"flexible-testimonials__item__text"},[_vm._v(" "+_vm._s(_vm.modalArticle.text)+" ")]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"flexible-testimonials__item__icon icon",attrs:{"symbol":"ui-testimonial","size":"0 0 28 24"}})])]},proxy:true}],null,false,552701922)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }